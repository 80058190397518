import Swiper, { Navigation, Pagination } from 'swiper';
import ScrollTo from './scrollTo'

import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';

import 'whatwg-fetch'

import 'swiper/swiper-bundle.css';
import '../scss/style.scss';

const header = document.querySelector('.header');
const headerBurger = document.querySelector('.header__burger');
const menuLinks = document.querySelectorAll('.header__link');

let vw = (window.innerWidth > 1920)?1920:window.innerWidth;

if (header) {
  document.addEventListener('scroll', () => {
    if (window.innerWidth > 991) {
      if(pageYOffset >= header.offsetHeight){
        if (!header.classList.contains('header__line-menu--fixed')){
          header.classList.add('header__line-menu--fixed');
        }
      } else {
        if (header.classList.contains('header__line-menu--fixed')){
          header.classList.remove('header__line-menu--fixed');
        }
      }
    }
  });
}

if (menuLinks) {
  menuLinks.forEach(el => {
    el.addEventListener('click', e =>{
      e.preventDefault();
      if (header.classList.contains('header-active')){
        header.classList.remove('header-active');
        document.body.style.overflowY = 'auto';
      }
      ScrollTo(document.querySelector(el.getAttribute('href')));
    });
  });
}

const buyBtns = document.querySelectorAll('.header__button');
if (buyBtns.length > 0) {
  buyBtns.forEach(el => {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      ScrollTo(document.querySelector(el.getAttribute('href')));
    })
  })
}

if (headerBurger) {
  headerBurger.addEventListener('click', (e) => {
    e.preventDefault();
    header.classList.toggle('header-active');
    document.body.style.overflowY = (header.classList.contains('header-active'))?'hidden':'auto';
  });
}

Swiper.use([Navigation]);

const swiper8 = new Swiper('.section-8__items', {
  loop: true,
  clickable: true,
  autoHeight: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  slidesPerView: 1,
  spaceBetween: 10,
  breakpoints: {
    // when window width is >= 320px
    768: {
      slidesPerView: 2,
      spaceBetween: 20
    },
    // when window width is >= 480px
    1280: {
      slidesPerView: 2,
      spaceBetween: 80,
    },
    1600: {
      slidesPerView: 2,
      spaceBetween: 100,
    }
  }
});

const faqItems = document.querySelectorAll('.section-7__item_title');

if (faqItems.length > 0){
  faqItems.forEach(el => {
    el.addEventListener('click', e => {
      e.preventDefault();
      let curr = document.querySelector('.section-7__item-active');
      if (curr) 
        curr.classList.remove('section-7__item-active');
      if (curr != el.parentElement)
        el.parentElement.classList.add('section-7__item-active')
    })
  })
}

const buyFixed = document.querySelector('.buy-fixed');

if (buyFixed) {
  setTimeout(()=>{
    buyFixed.classList.add('buy-fixed--active');
  }, 200);

  buyFixed.addEventListener('mouseover', ()=>{
    buyFixed.classList.add('buy-fixed--active');
  });

  document.addEventListener('scroll', () => {
    if (buyFixed.classList.contains('buy-fixed--active')){
      buyFixed.classList.remove('buy-fixed--active');
    }
  })
}


let thresholdSet = [];

for (let i=0; i <= .5; i += 0.01) {
	thresholdSet.push(i);
}

let breeze1Cherry = document.querySelector('.breeze-1__cherry');
if (breeze1Cherry){
  let observerBar = new IntersectionObserver((entries)=>
  entries.forEach(entry => {
    let ratio = entry.intersectionRatio;
    breeze1Cherry.style.transform = `scale(${(ratio+.5 > 1)?1:ratio+.5})`;
  }), {threshold: thresholdSet});
  observerBar.observe(document.querySelector('.breeze-1'));
}

const breeze1Bottle = document.querySelector('.breeze-1__bottle');
if (breeze1Bottle) {
  const callback = (entries) => {
    if(entries[0].intersectionRatio > .5 && breeze1Bottle.classList.contains('breeze-1__bottle-hide'))
    breeze1Bottle.classList.remove('breeze-1__bottle-hide');
  };
  var observer = new IntersectionObserver(callback, {threshold: .5});

  observer.observe(breeze1Bottle);
}

const breeze3Items = document.querySelectorAll('.breeze-3__item');
if (breeze3Items.length > 0) {
  breeze3Items.forEach(el => {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      if (!el.classList.contains('breeze-3__item-active')) {
        document.querySelector('.breeze-3__item-active').classList.remove('breeze-3__item-active');
        el.classList.add('breeze-3__item-active');
      }
    });
  })
}

const breeze4Items = document.querySelectorAll('.breeze-4__item-hide');
if (breeze4Items.length > 0) {
  breeze4Items.forEach(el => {
    const callback = (entries) => {
      if(entries[0].intersectionRatio > .9 && el.classList.contains('breeze-4__item-hide'))
        el.classList.remove('breeze-4__item-hide');
    };
    var observer = new IntersectionObserver(callback, {threshold: 1});

    observer.observe(el);
  
  })
}


const breeze4Cherry = document.querySelector('.breeze-4__cherry');
if (breeze4Cherry){
  let observerBar = new IntersectionObserver((entries)=>
  entries.forEach(entry => {
    let ratio = entry.intersectionRatio;
    breeze4Cherry.style.transform = `translateY(-${ratio*5}em)`;
  }), {threshold: thresholdSet});
  observerBar.observe(document.querySelector('.breeze-4'));
}

const btnForm = document.querySelector('.section-7__button');
const checkEmail = email => (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email);
if (btnForm) {
  btnForm.addEventListener('click', (e) => {
    e.preventDefault();
    let mess = document.querySelector('.section-7__form_textarea');
    let name = document.querySelector('.section-7__form_input-name');
    let email = document.querySelector('.section-7__form_input-email');
    let flag = name.value != "";
    let formError = document.querySelector('.section-7__form_error');
    formError.classList.remove('section-7__form_error-active');
    console.log(name, mess, email)

    mess.parentElement.classList.toggle('section-7__form_field-error', mess.value == "");
    formError.innerHTML = (mess.value == "")?'Поле вопрос не заполнено<br>':'';

    name.parentElement.classList.toggle('section-7__form_field-error', name.value == "");
    formError.innerHTML += (name.value == "")?'Поле Имя не заполнено<br>':'';
    
    flag = flag && checkEmail(email.value);
    email.parentElement.classList.toggle('section-7__form_field-error', !checkEmail(email.value));
    formError.innerHTML += (!checkEmail(email.value))?'Поле E-mail заполнено неверно':'';

    if (!flag) {
      formError.classList.add('section-7__form_error-active');
    } else {
      let response = window.fetch('./mail.php', {
        method: 'POST',
        body: JSON.stringify({name: name.value, email: email.value, text: mess.value}),
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
      }).then(response => response.json())
      .then( result => {
        if(result.res){
          const formSuccess = document.querySelector('.section-7__form');
          formSuccess.classList.add('section-7__form-success');
          formSuccess.querySelector('.section-7__form_title').innerHTML = 'Спасибо, '+name.value+'!';
          formSuccess.querySelector('.section-7__form_subtitle').innerHTML = 'Ваша заявка успешно отправлена!<br>Мы скоро свяжемся с вами!';
          document.querySelector('.section-7__col-rightside').classList.add('section-7__col-rightside--align-center');
          mess.value = "";
          email.value = "";
          name.value = "";
        } else {
          formError.innerHTML = 'К сожалению, что-то пошло не так. Попытайтесь повторно отправить вопрос через некоторое время.';
        }
      });
    }

  })
}

let swiper = [];
const swiperArr = ['.breeze-2', '.breeze-6']
const breakPoint = 768;

const sliderInit = () => {
  swiperArr.forEach((el, i) => swiper[i] = new Swiper(`${el}__items`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 20,
      navigation: {
        nextEl: `${el}__navigation-next`,
        prevEl: `${el}__navigation-prev`,
      }
    })
  )
}

let smplBar = false;

const resizeHandlerSlider = () => {
    if (swiper.length == 0 && document.documentElement.clientWidth < breakPoint) {
      sliderInit();
      smplBar = new SimpleBar(document.querySelector('.breeze-5__schema'));
    } 
    if (swiper.length > 0 && document.documentElement.clientWidth > breakPoint) {
      swiper.forEach(el => el.destroy());
      smplBar.unMount();
      swiper = [];
    }
}
resizeHandlerSlider();
window.addEventListener('resize', resizeHandlerSlider);